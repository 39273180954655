@use '../util/' as *;
// Research: if you use "use" you can use a namespace to differentiate between multiple styles.

.cards{
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(30);

    @include breakpoint(medium){
        grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(large){
        grid-template-columns: repeat(4, 1fr);
    }
}

.card {
    background: var(--card);
    color: var(--text-secondary);
    flex: 1;
    padding: rem(25);
    border-radius: rem(5);
    font-size: rem(12);
    font-weight: 700;
    transition: background 150ms ease-in-out;

    &:hover{
        background: var(--card-hover);
    }

    // LARGE CARD
    &.large {
        border-top: rem(5) solid;
        text-align: center;
        //margin-bottom: rem(24);

        // VARIATIONS
        &.facebook {
            border-color: var(--facebook);
        }
    
        &.twitter {
            border-color: var(--twitter);
        }

        /* Gradient borders aren't supported by using CSS (https://www.geeksforgeeks.org/gradient-borders) */
        &.instagram {
            border-top: none;
            position: relative;
            overflow: hidden;
            padding: rem(30);

            &::before {
                content:"";
                position:absolute;
                top: 0;
                left: 0;
                padding: rem(3);
                width: 100%;
                background: linear-gradient(45deg, var(--instagram-start), var(--instagram-end));
                mask-composite: exclude; 
            }
        }

        &.youtube {
            border-color: var(--youtube);
        }

        // CARD HEADER
        & .platform {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: rem(28);
            height: rem(20);

            // Platform icon
            & img {
                margin-right: 8px;
            }

            // .username {}
        }

        //CARD CONTENT
        & .content {
            margin-bottom: rem(25);
            
            & .count {
                font-size: rem(56);
            }

            & .label {
                text-transform: uppercase;
                font-weight: 400;
                letter-spacing: rem(5);
            }
        }
    }

    // SMALL CARD
    &.small {
        display: grid;
        /*margin-bottom: rem(16);*/
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        gap: rem(22.5);
        justify-content: space-between;
        align-items: center;

        /*
        & .platform {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }*/

        .metric {
            font-size: rem(14);
        }

        .count{
            font-size: rem(32);
            color: var(-- text-primary);
            margin-bottom: 0;
        }

        .count, .change {
            align-self: end;
        }

        img, .change {
            justify-self: end;
        }
    }

    // RESET PARAGRAPH STYLES FOR CARD ONLY
    & p {
        margin: 0;
        padding: 0;
        line-height: 1.1;
    }

    & .count {
        color: var(--text-primary);
        letter-spacing: rem(-2);
        line-height: 1;
        margin-bottom: rem(4);
    }

    // STAT CHANGE
    & .change {
        display: flex;
        justify-content: center;
        align-items: center;

        & img{
            margin-right: rem(4);
        }

        &.increase {
            color: var(--change-up);
        }

        &.decrease {
            color: var(--change-down);
        }
    }

}