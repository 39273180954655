:root {
  --font-inter: "Inter", sans-serif;
}

/* HOW TO USE IT

body {
    font-family: var(--font-inter);
}

*/
:root {
  --change-up: hsl(163, 72%, 41%);
  --change-down: hsl(356, 69%, 56%);
  --facebook: hsl(208, 92%, 53%);
  --twitter: hsl(203, 89%, 53%);
  --instagram-start: hsl(37, 97%, 70%);
  --instagram-end: hsl(329, 70%, 58%);
  --youtube: hsl(348, 97%, 39%);
  --toggle-bg-start: hsl(146, 68%, 55%);
  --toggle-bg-end: hsl(210, 78%, 56%);
  --toggle: hsl(230, 22%, 74%);
  --dark-toggle-switch: hsl(229, 25%, 27%);
  --light-toggle-switch: hsl(234, 56%, 96%);
  --dark-bg-end: hsl(230, 17%, 14%);
  --dark-bg-start: hsl(232, 19%, 15%);
  --dark-card: hsl(228, 28%, 20%);
  --dark-card-hover: hsl(228, 25%, 27%);
  --dark-text-primary: hsl(0, 0%, 100%);
  --dark-text-sec: hsl(228, 34%, 66%);
  --dark-header-border: hsl(232, 17%, 26%);
  --light-bg-end: hsl(0, 0%, 100%);
  --light-top-start: hsl(225, 100%, 98%);
  --light-card: hsl(227, 47%, 96%);
  --light-card-hover: hsl(232, 33%, 91%);
  --light-text-primary: hsl(230, 17%, 14%);
  --light-text-sec: hsl(228, 12%, 44%);
  --light-header-border: hsl(230, 8%, 57%);
  --background: linear-gradient(180deg, var(--dark-bg-start) 0%, var(--dark-bg-start) 100%);
  --text-primary: var(--dark-text-primary);
  --text-secondary: var(--dark-text-sec);
  --card: var(--dark-card);
  --card-hover: var(--dark-card-hover);
  --toggle-switch: var(--dark-toggle-switch);
  --header-border: var(--dark-header-border);
}

@media (prefers-color-scheme: light) {
  :root {
    --background: linear-gradient(180deg, var(--light-bg-start) 0%, var(--light-bg-start) 100%);
    --text-primary: var(--light-text-primary);
    --text-secondary: var(--light-text-sec);
    --card: var(--light-card);
    --card-hover: var(--light-card-hover);
    --toggle-switch: var(--light-toggle-switch);
    --header-border: var(--light-header-border);
  }
}
body.light {
  --background: linear-gradient(180deg, var(--light-bg-start) 0%, var(--light-bg-start) 100%);
  --text-primary: var(--light-text-primary);
  --text-secondary: var(--light-text-sec);
  --card: var(--light-card);
  --card-hover: var(--light-card-hover);
  --toggle-switch: var(--light-toggle-switch);
  --header-border: var(--light-header-border);
}

body.dark {
  --background: linear-gradient(180deg, var(--dark-bg-start) 0%, var(--dark-bg-start) 100%);
  --text-primary: var(--dark-text-primary);
  --text-secondary: var(--dark-text-sec);
  --card: var(--dark-card);
  --card-hover: var(--dark-card-hover);
  --toggle-switch: var(--dark-toggle-switch);
  --header-border: var(--dark-header-border);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-inter);
  background: var(--background);
  color: var(--text-primary);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

/* if you want to use the mixin in the code, type the following: @include breakpoint(large){<your-rule>}

Example: 

h1 {
    @include breakpoint(large){
        font-size: 3rem;
    }
}
 */
/* HOW TO USE IT

If you want a 30px h1 tag:

---
h1 {
    font-size: rem(30);
}
---

and it will output:

---
h1 {
    font-size: 1.875rem;
}
---

*/
h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 0.3125rem;
}
@media (min-width: 71.875em) {
  h1 {
    font-size: 1.75rem;
  }
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

a, a:visited, a:active {
  text-decoration: none;
}

.container {
  padding: 0 1.5625rem;
  max-width: 68.75rem;
  margin: 0 auto 2.5rem;
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
  }
}

.header {
  margin-top: 2.25rem;
}
@media (min-width: 40em) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.header .subtitle {
  display: inline-block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--text-secondary);
  letter-spacing: 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--header-border);
}
@media (min-width: 40em) {
  .header .subtitle {
    padding-bottom: 0;
    border-bottom: none;
  }
}

/*
https://kittygiraudel.com/2021/04/05/an-accessible-toggle/
https://adrianroselli.com/2019/08/under-engineered-toggles-too.html
https://codepen.io/aardrian/pen/JgLGPB
*/
.toggle-container {
  padding-top: 1rem;
}

.toggles {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toggles label {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--text-secondary);
  padding-right: 1.625rem;
}

.toggles [aria-pressed] {
  box-sizing: border-box;
  border: none;
  background: none;
  font-size: 0.625rem;
  color: var(--text-secondary);
  font-weight: 700;
  line-height: inherit;
  text-align: left;
  padding: 0.375rem 0 0.375rem 0rem;
  position: relative;
  visibility: hidden;
  /*&:focus, &:hover {
      color: #00f;
      outline: none;
  }

  &:focus::before, &:hover::before {
      box-shadow: 0 0 0.5em #333;
  }*/
}
.toggles [aria-pressed]::before, .toggles [aria-pressed]::after {
  content: "";
  position: absolute;
  transition: all 0.25s ease;
}
.toggles [aria-pressed]::before {
  left: 0;
  top: 0.2em;
  width: 3rem;
  height: 1.5rem;
  /*border: 1px solid var(--dark-bg-end);*/
  background: linear-gradient(225deg, var(--toggle-bg-start) 0%, var(--toggle-bg-end) 98.02%);
  border-radius: 0.9375rem;
  visibility: visible;
}
.toggles [aria-pressed]::after {
  left: 0.1875rem;
  top: 0.3125rem;
  background-color: var(--toggle-switch);
  border-radius: 50%;
  width: 1.125rem;
  height: 1.125rem;
  /*border: 1px solid white;*/
  visibility: visible;
}
.toggles [aria-pressed]:focus::after, .toggles [aria-pressed]:hover:after {
  background-color: var(--toggle-switch);
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center center;
}
.toggles [aria-pressed][disabled], .toggles [aria-pressed][disabled]:hover {
  color: #999;
}
.toggles [aria-pressed][disabled]::before {
  /*background-color: transparent;*/
  border-color: #ddd;
}
.toggles [aria-pressed][disabled]::after {
  border-color: #ddd;
}
.toggles [aria-pressed][disabled]:hover {
  color: #999;
  /* case for CSS custom property if not supporting IE/Edge */
}
.toggles [aria-pressed][disabled]:hover::before {
  box-shadow: none;
}
.toggles [aria-pressed][disabled]:hover::after {
  background-image: none;
}

.toggles [aria-pressed=true]::after {
  left: 1.6em;
  /*
  border-color: #36a829;
  color: #36a829;
  */
}
.toggles [aria-pressed=true]::before {
  background: var(--toggle);
}

/* Put toggles on the right like the iOS the kids like */
.toggles.flip [aria-pressed]::before,
.toggles.flip [aria-pressed]::after {
  left: auto;
  right: 0;
}

.toggles.flip [aria-pressed]::after {
  left: auto;
  right: 1.6875rem;
}

.toggles.flip [aria-pressed=true]::after {
  right: 0.1875rem;
}

.toggles.flip [aria-pressed] {
  padding-left: 0;
  padding-right: 0;
}

/* Windows High Contrast Mode Support */
@media screen and (-ms-high-contrast: active) {
  .toggles [aria-pressed]:focus::before,
.toggles [aria-pressed]:hover::before {
    outline: 1px dotted windowText;
    outline-offset: 0.25em;
  }

  .toggles [aria-pressed]::after {
    background-color: windowText;
  }

  /*.toggles [aria-pressed][disabled]::after {
    background-color: transparent;
  }*/
}
/* Reduced motion */
@media screen and (prefers-reduced-motion: reduce) {
  .toggles [aria-pressed]::before,
.toggles [aria-pressed]::after {
    transition: none;
  }
}
/* Dark mode 
@media screen and (prefers-color-scheme: dark) {
  form {
    background-color: #101010;
  }
  .toggles {
    color: #fff;
  }
  fieldset.toggles {
    border: 0.1em solid rgba(255, 255, 255, 0.15);
  }
  .toggles [aria-pressed]:focus,
  .toggles [aria-pressed]:hover {
    color: #99f;
  }
  .toggles [aria-pressed]::before {
    border-color: #808080;
    background: #808080;
  }
  .toggles [aria-pressed]::after {
    background-color: #101010;
  }
  .toggles [aria-pressed]:focus::after,
  .toggles [aria-pressed]:hover::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='50' cy='50' r='50' fill='rgba(255,255,255,.25)'/%3E%3C/svg%3E");
  }
  .toggles [aria-pressed][disabled]::before,
  .toggles [aria-pressed][disabled]::after {
    border-color: #555;
  }
}
*/
.cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
}
@media (min-width: 40em) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 71.875em) {
  .cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  background: var(--card);
  color: var(--text-secondary);
  flex: 1;
  padding: 1.5625rem;
  border-radius: 0.3125rem;
  font-size: 0.75rem;
  font-weight: 700;
  transition: background 150ms ease-in-out;
}
.card:hover {
  background: var(--card-hover);
}
.card.large {
  border-top: 0.3125rem solid;
  text-align: center;
  /* Gradient borders aren't supported by using CSS (https://www.geeksforgeeks.org/gradient-borders) */
}
.card.large.facebook {
  border-color: var(--facebook);
}
.card.large.twitter {
  border-color: var(--twitter);
}
.card.large.instagram {
  border-top: none;
  position: relative;
  overflow: hidden;
  padding: 1.875rem;
}
.card.large.instagram::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.1875rem;
  width: 100%;
  background: linear-gradient(45deg, var(--instagram-start), var(--instagram-end));
  mask-composite: exclude;
}
.card.large.youtube {
  border-color: var(--youtube);
}
.card.large .platform {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.75rem;
  height: 1.25rem;
}
.card.large .platform img {
  margin-right: 8px;
}
.card.large .content {
  margin-bottom: 1.5625rem;
}
.card.large .content .count {
  font-size: 3.5rem;
}
.card.large .content .label {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.3125rem;
}
.card.small {
  display: grid;
  /*margin-bottom: rem(16);*/
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 1.40625rem;
  justify-content: space-between;
  align-items: center;
  /*
  & .platform {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }*/
}
.card.small .metric {
  font-size: 0.875rem;
}
.card.small .count {
  font-size: 2rem;
  color: var(-- text-primary);
  margin-bottom: 0;
}
.card.small .count, .card.small .change {
  align-self: end;
}
.card.small img, .card.small .change {
  justify-self: end;
}
.card p {
  margin: 0;
  padding: 0;
  line-height: 1.1;
}
.card .count {
  color: var(--text-primary);
  letter-spacing: -0.125rem;
  line-height: 1;
  margin-bottom: 0.25rem;
}
.card .change {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card .change img {
  margin-right: 0.25rem;
}
.card .change.increase {
  color: var(--change-up);
}
.card .change.decrease {
  color: var(--change-down);
}