:root {
    --font-inter: 'Inter', sans-serif;
}

/* HOW TO USE IT

body {
    font-family: var(--font-inter);
}

*/